import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '@/components/Layout';
import { PageContainer, PageHeader } from '@/components/elements';
import Seo from '@/components/Seo';
import CtaIconLinks from '@/components/CTA/CtaIconLinks';
import TermsIcon from '@/assets/svg/icon-terms.svg';
import PrivacyIcon from '@/assets/svg/icon-privacy.svg';
import PricePlan from '@/components/PricePlan';
import Faq from '@/components/Faq';
import KeyPoints from '@/components/KeyPoints';
import ContentSection from '@/components/ContentSection';
import { planItemsItems } from '@/data/priceplans';
import { hostingFaqData } from '@/data/faq';
import IconKeyPointSafeSecure from '@/assets/svg/icon-safe-secure-white.svg';
import IconKeyPointUptime from '@/assets/svg/icon-uptime-white.svg';
import IconKeyPointSupport from '@/assets/svg/icon-support-white.svg';
import IconKeyPointOptimized from '@/assets/svg/icon-server-opitimized-white.svg';

const HostingPage: React.FC<PageProps> = () => (
  <Layout>
    <Seo title="Website Hosting | MyServerJapan" description="" lang="en" />
    <PageContainer>
      <PageHeader
        title="WEB HOSTING"
        subtitle="Whether you're looking to create a website for your startup, or to move your current system to faster servers in Japan, we can get you started with a hosting plan tailored to your specific needs."
        bgImage={
          <StaticImage
            src="../assets/images/about-header-bg.png"
            alt=""
            quality={100}
            layout={`fixed`}
            height={300}
          />
        }
        bgColor={`primary`}
      />

      <PricePlan
        title="Shared Web Hosting"
        description="Our Shared Hosting plans are designed to provide you with the tools needed at affordable prices. Our plans are all upgradable, just click Get Started and begin the process!"
        items={planItemsItems}
        btnLabel="Get Started"
        footerText="*All prices include tax."
        //  fullwidth
      />

      <ContentSection title="Benefits" bgColor={`primary`}>
        <p>
          We utilize top-of-the-line servers in Japan and other parts of the
          world to power your websites. Each server is optimized for speed and
          limited to how many clients can operate on each. This powerful
          combination of resources, performance enhancements, and limited
          occupancy means faster page load times that help improve your SEO and
          your visitors experience!
        </p>
      </ContentSection>

      <KeyPoints
        //  title={'title here'}
        //  description={'text here'}
        //  iconColor={pageColor}
        items={[
          {
            title: `Safe & Secure`,
            text: `Our servers are constantly updated to make sure they are safe and secure!`,
            icon: <IconKeyPointSafeSecure />,
          },
          {
            title: `99.9% Up time`,
            text: `We are proud to say that our servers are up 99.9% of the time.`,
            icon: <IconKeyPointUptime />,
          },
          {
            title: `Optimized for Japan`,
            text: `Get the best speed and performance for your website in Japan and Asia.`,
            icon: <IconKeyPointOptimized />,
          },
          {
            title: `English Support`,
            text: `All are systems are in English & Japanese as standard.`,
            icon: <IconKeyPointSupport />,
          },
        ]}
      />
      <Faq
        title="FAQ"
        lang={`en`}
        items={hostingFaqData}
        description="Have a question or not sure about something?"
        bgColor={`primaryLighter`}
        fullwidth={false}
      />
      <CtaIconLinks
        // bgColor={`primaryLighter`}
        //  title="Legal"
        //  description="Take a look at our hosting plans!"
        items={[
          {
            id: `terms`,
            title: `Terms & Conditions`,
            icon: <TermsIcon />,
            url: `/legal/terms-of-use`,
          },
          {
            id: `privacy`,
            title: `Privacy Policy`,
            icon: <PrivacyIcon />,
            url: `/legal/privacy-policy`,
          },
        ]}
        //  fullwidth
      />
    </PageContainer>
  </Layout>
);

export default HostingPage;
